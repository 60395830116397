table.sortable thead tr th.-sort-desc:after {
  content: '\F0DD';
  top: -3px;
  position: relative;
  display: inline-block;
  bottom: 0px;
  right: -7px;
  font-family: 'FontAwesome';
  opacity: 1;
}

table.sortable thead tr th.-sort-asc:after {
  content: '\F0DE';
  top: 3px;
  position: relative;
  display: inline-block;
  bottom: 0px;
  right: -7px;
  font-family: 'FontAwesome';
  opacity: 1;
}

.mw-200px {
  max-width: 200px;
}

.mw-300px {
  max-width: 300px;
}

.mw-400px {
  max-width: 400px;
}

.mw-600px {
  max-width: 600px;
}

.clickable {
  cursor: pointer;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
